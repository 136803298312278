export enum ApiUrls {
	//calculation
	RUN_CALCULATION_PREDEFINED = 'Calculation/job/{jobId}/start-predefined',
	RUN_CALCULATION_WITH_SETTINGS = 'Calculation/job/{jobId}/start-custom',
	RUN_CALCULATION_MOST_RECENT = 'Calculation/job/{jobId}/most-recent',
	GET_RECENT_CALCULATIONS = 'Calculation/job/{jobId}/recent-runs/{take}',
	GET_CALCULATION_SETTINGS_ANALYSIS_GROUPS = 'calculationSettings/job/{jobId}/analysisGroups',

	//Dashboard
	GET_DASHBOARD_SUMMARY = 'Dashboard/job/{jobId}/summary/{calculationProcessId}',
	GET_PIE_CHARTS_DATA = 'charts/job/{jobId}/pie-chart',
	GET_BAR_CHARTS_DATA = 'charts/job/{jobId}/bar-chart',
	GET_DRILLDOWN_CHARTS_DATA = 'charts/job/{jobId}/drillDown-chart',
	GET_BOOKTAXABLE_CHARTS_DATA = 'charts/job/{jobId}/booktaxable-chart',

	//report
	GET_OUTPUT_REPORT_CONTENT = 'report/{jobId}/{type}',
	DOWNLOAD_REPORT = 'job/{jobId}/calculation/{calculationId}/report',
	DOWNLOAD_ERROR = 'job/{jobId}/calculation/{calculationId}/error',
	DOWNLOAD_REPORTS = 'report/client/{clientId}/job/{jobId}/import/{importId}/download',

	//Reference Table
	GET_CLIENT_REFERENCE_LIBRARY = 'client/{clientId}/client-reference',
	UPLOAD_REFERENCE_FILE = 'client/{clientId}/client-reference',
	DOWNLOAD_REFERENCE_FILE = 'client/{clientId}/client-reference/{id}/file-download/{type}',
	UPDATE_REFERENCE_FILE = 'client/{clientId}/client-reference/{id}/update',
	RSM_REFERENCE_LIBRARY = 'rsm-standard-reference',
	DOWNLOAD_RSM_REFERENCE_FILE = 'rsm-standard-reference/{id}/download',
	CHECK_CONNECTED_JOBS_FOR_CLIENT_REFERENCE_TABLES = 'client/{clientId}/client-reference/{id}/connected-jobs',

	//Ruleset
	GET_CLIENT_RULESET = 'ruleset/client/{clientId}',
	GET_RSM_RULESET = 'ruleset',
	UPLOAD_CLIENT_RULESET = 'ruleset/client-upload/{clientId}',
	UPLOAD_RSM_RULESET = 'ruleset/upload',
	UPDATE_CLIENT_RULESET = 'ruleset/client/{clientId}/update-metadata',
	UPDATE_RSM_RULESET = 'ruleset/update-metadata',
	CHECK_CONNECTED_JOBS_FOR_RULESET = 'ruleset/{rulesetId}/connected-jobs',

	//Asset warehouse
	GET_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client/{clientId}',
	GET_RSM_ASSETWAREHOUSE = 'asset-warehouse',
	UPLOAD_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client-upload/{clientId}',
	UPLOAD_RSM_ASSETWAREHOUSE = 'asset-warehouse/upload',
	UPDATE_CLIENT_ASSETWAREHOUSE = 'asset-warehouse/client/{clientId}/update-metadata',
	UPDATE_RSM_ASSETWAREHOUSE = 'asset-warehouse/update-metadata',

	//Dividend Table
	GET_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client/{clientId}',
	GET_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse',
	UPLOAD_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client-upload/{clientId}',
	UPLOAD_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse/upload',
	DOWNLOAD_DIVIDEND_WAREHOUSE = 'file-download/{fileUploadId}',
	UPDATE_CLIENT_DIVIDEND_WAREHOUSE = 'dividend-warehouse/client/{clientId}/update-metadata',
	UPDATE_RSM_DIVIDEND_WAREHOUSE = 'dividend-warehouse/update-metadata',

	//Inputs
	GET_DATASET_INPUT_JSON = 'job/{jobId}/dataset/{datasetId}/input/{type}',
	GET_LIBRARY_INPUT_JSON = 'Inputs/{jobId}/{id}/type/{type}',
	GET_CALCULATION_INPUT = 'Dataset/job/{jobId}/{importId}',

	//Usage Statistics
	GET_IMPORT_STATISTICS = 'importStatistics/{startDate}/{endDate}',
	GET_CALCULATION_STATISTICS = 'calculationStatistics/{startDate}/{endDate}',

	//Job
	BASE_JOB_URL = 'job/{jobId}/',
	CREATE_STATUS_DETAIL = 'detail/job/{jobId}/create',
	GET_STATUS_DETAIL = 'detail/job/{jobId}/most-recent',
	CREATE_EVENT = 'event/job/{jobId}/type/{eventType}',
	GET_EVENTS = 'event/job/{jobId}',
	GET_FAVORITE_JOBS = 'favoriteJobs',
	CREATE_FAVORITE_JOBS = 'favoriteJobs/job/{jobId}',
	REMOVE_FAVORITE_JOBS = 'favoriteJobs/job/{jobId}',

	//Template
	DOWNLOAD_OR_UPLOAD_LATEST_TEMPLATE = 'template',
	FETCH_TEMPLATE_HISTORY = 'template/history',
	EDIT_TEMPLATE_DETAILS = 'template/{templateId}',
	DOWNLOAD_TEMPLATE = 'template/{templateId}',
	DOWNLOAD_EMPTY_TEMPLATE = 'template/job/{jobId}',
	DOWNLOAD_ACTIVE_TEMPLATE = 'job/{jobId}/dataset/{datasetId}',

	//Pricing
	GET_VOLUME_MULTIPLIER = 'volumemultiplier',
	CREATE_VOLUME_MULTIPLIER = 'volumemultiplier/create',
	UPDATE_VOLUME_MULTIPLIER = 'volumemultiplier/{id}/update',
	DELETE_VOLUME_MULTIPLIER = 'volumemultiplier/{id}/delete',

	GET_CALCULATION_SETTINGS_FACTOR = 'calculationsettingsfactor',
	CREATE_CALCULATION_SETTINGS_FACTOR = 'calculationsettingsfactor/create',
	UPDATE_CALCULATION_SETTINGS_FACTOR = 'calculationsettingsfactor/{id}/update',
	DELETE_CALCULATION_SETTINGS_FACTOR = 'calculationsettingsfactor/{id}/delete',

	CREATE_CALCULATION_SUB_SETTINGS_FACTOR = 'calculationsubsettingsfactor/create',
	GET_CALCULATION_SUB_SETTINGS_FACTOR = 'calculationsubsettingsfactor',
	UPDATE_CALCULATION_SUB_SETTINGS_FACTOR = 'calculationsubsettingsfactor/{id}/update',
	DELETE_CALCULATION_SUB_SETTINGS_FACTOR = 'calculationsubsettingsfactor/{id}/delete',

	CREATE_PRICING_OVERRIDE = 'pricingoverride/create',
	UPDATE_PRICING_OVERRIDE = 'pricingoverride/{id}/update',
	GET_PRICING_OVERRIDE = 'pricingoverride',
	DELETE_PRICING_OVERRIDE = 'pricingoverride/{id}/delete',

	GET_PRICING = 'pricing/',
	CREATE_PRICING = 'pricing/create',
	UPDATE_PRICING = 'pricing/{id}/update',
	DELETE_PRICING = 'pricing/{id}/delete',

	//File Download
	DOWNLOAD_FILE_FROM_JOB = 'file-download/job/{jobId}/{fileId}',
	DOWNLOAD_FILE = 'file-download/{fileId}',
}