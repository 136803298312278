import { Section } from './Section';

export enum InputType {
	Asset = 1,
	Payment = 7,
	Rollover = 8,
	TaxLots = 9,
	RealizedGainLoss = 10,
	RolloverOriginal = 11,
	Diagnostics = 13,
}

export enum LibraryType {
	ClientAssetWarehouse = 1,
	ClientDividendWarehouse = 2,
	ClientRuleset = 3,
	ReferenceAssetType = 19,
	ReferenceExchange = 20,
	ReferenceCountry = 21,
	ReferenceTransaction = 22,
	ReferenceCurrency = 23,
}

export enum GroupType {
	Dataset = 101,
	Library,
}

export enum AdminType {
	Default = 201,
	Client,
}

export interface InputDetailsProps {
	InputName: string;
	InputType?: InputType | LibraryType;
	Section: Section;
	SubSectionName: string;
	Group: GroupType;
}

export enum OutputType {
	GainLoss = 0,
	Combined = 1,
	ConstructiveSales = 2,
	StraddleDetails = 3,
	Rollover = 4,
	BookToTax = 5,
	DividendDetails = 6,
	IncomeByCharacterAndCountry = 7,
	TaxableIncome = 8,
	Dividend = 9,
	Settings = 10,
	BookTaxable = 11,
	DrillDown = 12,
	RollForward = 13,
	OneToOne = 14
}